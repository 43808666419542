<template>
  <div id="project">
    <vs-progress class="w-full" v-if="!loaded" indeterminate color="primary"></vs-progress>
    <div v-else>
        {{ project }}
        <div class="vx-row w-full">
        <div class="vx-col w-2/5">
          <vs-input class="mt-2" :label="$t('project_id')" v-model="projectEdited.project_id" :disabled="true"/>
          <vs-input class="mt-2" :label="$t('project_slug')" v-model="projectEdited.project_slug"/>
          <vs-input class="mt-2" :label="$t('project_nicename')" v-model="projectEdited.project_nicename"/>
          <vs-input class="mt-2" :label="$t('project_status')" v-model="projectEdited.project_status"/>
        </div>
        <div class="vx-col w-2/5">
          <vs-input class="mt-2" :label="$t('keywords')" v-model="projectEdited.keywords"/>
          <vs-input class="mt-2" :label="$t('project_coordinate_system')" v-model="projectEdited.project_coordinate_system"/>
          <vs-input class="mt-2" :label="$t('project_lat')" v-model="projectEdited.project_lat"/>
          <vs-input class="mt-2" :label="$t('project_lng')" v-model="projectEdited.project_lng"/>
        </div>
        <div class="vx-col w-1/5">
          <vs-button class="m-6 w-full" @click="dispatchUpdates" :disabled="!diffsToApiQuery">{{this.$t('Save')}}</vs-button>
          <pre>
            {{diffsToApiQuery}}
          </pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios.js'
import { getDiff } from 'recursive-diff'

export default {
  components: {
  },
  data () {
    return {
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      apiRoute: '/admin',
      loaded: true,
      project_slug: null,
      project: null,
      projectEdited: null
    }
  },
  methods: {
    getEntityData () {
      const params = `project_slug=${this.project_slug}`
      const rimnat_api_call_url = `${this.api_server_baseurl}${this.apiRoute}/project/getProject?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          this.loaded = true
          this.project = response.data
          if (this.project.keywords != null && this.project.keywords != '') {
            this.project.keywords = JSON.parse(this.project.keywords)
          }
          this.projectEdited = JSON.parse(JSON.stringify(this.project))
          this.loaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getValues (serie) {
      return Object.entries(serie)
        .map(item => parseFloat(item[1].result))
    }, 
    dispatchUpdates () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('dispatchProjectUpdatesText'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          const params = new FormData()
          for (const [key, value] of Object.entries(this.diffsToApiQuery)) {
            params.set(key, JSON.stringify(value))
          }
          const rimnat_api_call_url = `${this.api_server_baseurl}/admin/project/updateProjectSettings`
          axios.post(rimnat_api_call_url, params)
            .then((response) => { 
              if (response.data[0] === 'ok' || response.data == 'ok') {
                this.$vs.notify({
                  color:'success',
                  title:this.$t('success'),
                  text:this.$t('success')
                })
              } else {
                console.log(response)
                this.$vs.notify({
                  color:'danger',
                  title:this.$t('error'),
                  text:response.data
                })
              }
              this.refreshAll()
            })
            .catch((error)   => { 
              console.log(error)
              this.$vs.notify({
                color:'danger',
                title:this.$t('error'),
                text:error
              })
              this.refreshAll()
            })
        }
      })
    }, 
    refreshAll () {
      this.loaded = false
      this.getEntityData()
    }
  },
 
  computed: {
    diffsToApiQuery () {
      if (this.diffs.length == 0) return 
      return { 
        context: {
          services_user_id: this.$store.state.AppActiveUser.user_id,
          services_user_hash: this.$store.state.AppActiveUser.user_hash
        }, 
        diffs: this.diffsToApi
      }
    },
    diffsToApi () {
      if (this.diffs.length == 0) return 
      const res = {
        project_slug : this.project_slug
      }
      const props = []
      this.diffs.forEach(e => {
        if (e.op == 'update' && e.val != e.oldVal) {
          const prop = e.path[0]
          const val = e.val
          props.push({prop, val})
        }
      })
      res.updates = props
      return res
    },
    diffs () {
      return getDiff(this.project, this.projectEdited, true)
    }
  },

  beforeMount () {
    this.project_slug = this.$route.params.project_slug
  },

  mounted () {
    document.body.classList.add('theme-dark')
    this.getEntityData()
  },

  watch: {
  }
}

</script>
<style lang="scss">

.theme-dark input {
    background: none !important;
}
</style>
